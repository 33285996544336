import axios from "axios";

export default {
    update(id, data) {
        return axios.put(`/api/customers/${id}`, data)
    },
    store(data) {
        return axios.post(`/api/customers`, data)
    },
    delete(id) {
        return axios.delete(`/api/customers/${id}`)
    }
}
